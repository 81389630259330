<template>
  <section>
    <v-overlay :value="isLoading" opacity="0.7" z-index="500">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="1200px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <span class="text-h5 mb-3">{{ trip.name }} Costs and Products</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="doSave">
            <v-row v-if="trip.dates.length >= 1">
              <v-expansion-panels class="pa-4">
                <v-expansion-panel v-for="(row, index) in trip.dates" :key="index">
                  <v-expansion-panel-header>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $moment(row.start).format('LL') }}  - {{ $moment(row.end).format('LL') }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ row.nights }} Night(s)
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-divider></v-divider>
                      <v-row class="mt-6">
                        <v-col sm="10">
                          <span class="title">
                            Products available
                          </span>
                        </v-col>
                      </v-row>
                      <div>
                        <v-row>
                          <v-col sm="3" v-for="(product, index) in row.products" :key="index" class="d-flex justify-center align-center">
                            <v-card class="v-ripple show-pointer rounded-lg" @click="doEditProduct(product.id)" elevation="5" style="height: 180px; width: 180px;">
                              <v-card-text class="pa-1">
                                <v-list class="pa-0">
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ product.name }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle class="mt-1">
                                        {{ product.category.name }}<br>
                                        <span v-if="product.isRequired">Required Purchase<br></span>
                                        ${{ product.cents / 100 }}
                                      </v-list-item-subtitle>
                                      <v-list-item-content>
                                        <p v-snip="{ lines: 2, mode: 'js', midWord: true }">
                                          {{ product.description }}
                                        </p>
                                      </v-list-item-content>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col sm="3" class="d-flex justify-center align-center">
                            <v-card class="v-ripple show-pointer rounded-lg" @click="doAddProduct(index)" elevation="5" color="primary" style="height: 180px; width: 180px;">
                              <v-card-text class="text-center white--text">
                                <div class="absolute-center">
                                  <font-awesome-icon icon="fa-solid fa-plus" class="mr-2"/>
                                  <span class="subtitle-1 text-uppercase font-weight-bold">Add</span>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
            <v-row v-else>
              <v-col>
                <div class="text-center">
                  Creating costs and products for {{ trip.name }} requires<br>
                  at least one date range set.<br>
                  <v-btn @click="doOpenDates" class="mt-10" color="primary">
                    Choose some dates
                  </v-btn>
                </div>

              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="doClose">Cancel</v-btn>
              </v-col>
              <v-col align="right">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-2">
                      Options
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <font-awesome-icon icon="fa-solid fa-calendar-days"/>
                      <v-list-item-title @click="doOpenDates" class="show-pointer ml-2">Dates</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <font-awesome-icon icon="fa-solid fa-location-pin"/>
                      <v-list-item-title @click="doOpenLocations" class="show-pointer ml-2">Locations</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn color="success" type="submit" class="ml-2" :disabled="userRole === 'employee'">Save</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <create-product ref="createProduct" @doLoad="doLoad"></create-product>
    <update-product ref="updateProduct" @doLoad="doLoad"></update-product>
  </section>
</template>

<script>
import CreateProduct from '@/views/admin/trips/createProduct.vue'
import UpdateProduct from '@/views/admin/trips/updateProduct.vue'

export default {
  name: 'viewProducts',
  components: { UpdateProduct, CreateProduct },
  data: () => ({
    isLoading: false,
    isOpen: false,
    trip: {
      id: Number(),
      name: String(),
      dates: []
    },
    userRole: null
  }),
  methods: {
    doOpen (id) {
      this.isLoading = true
      this.trip.id = id
      this.userRole = this.$store.getters.doGetRole

      this.doLoad()
    },
    doLoad (event = undefined, tripId = this.trip.id) {
      new Promise((resolve, reject) => {
        this.$services.trip.doGetById(tripId).then(trip => {
          resolve(trip)
        }).catch(error => {
          reject(error)
        })
      }).then(trip => {
        this.trip = trip
        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doClose () {
      this.isOpen = false
      this.doInit()
      this.$emit('doLoad')
    },
    doOpenDates () {
      this.$emit('viewDates', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doOpenLocations () {
      this.$emit('viewLocations', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doAddProduct (dateIndex) {
      this.$refs.createProduct.doOpen(this.tripId, this.trip.dates[dateIndex].id)
    },
    doEditProduct (tripProductId) {
      this.$refs.updateProduct.doOpen(this.tripId, tripProductId)
    },
    doSave () {
      this.doClose()
    },
    doInit () {
      this.trip = {
        id: Number(),
        name: String(),
        dates: []
      }
    }
  }
}
</script>

<style scoped>
.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
</style>
