<template>
  <section>
    <v-overlay :value="isLoading" opacity="0.7" z-index="500">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="1200px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <span class="text-h5">{{ trip.name }} Dates</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="doSave">
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <div style="display: flex; justify-content: space-between;" v-for="(date, index) in trip.dates" :key="index">
                      <v-checkbox
                        off-icon="fa-regular fa-square"
                        v-model="activePicker.index"
                        :true-value="index"
                        :false-value="null"
                        :disabled="userRole === 'employee'"
                      ></v-checkbox>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ date.start !== '' ? $moment(date.start).format('LL') : 'Choose some dates' }}
                              {{ date.end !== '' ? `to ${$moment(date.end).format('LL')}` : '' }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="date.end !== ''">
                              {{ doCalcNights(date.start, date.end)  }} Nights and {{ doCalcNights(date.start, date.end) + 1  }} Days
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-btn fab color="red" class="white--text mt-2 ml-auto" @click="doRemoveDate(index)" :disabled="userRole === 'employee'">
                        <font-awesome-icon :icon="['fas', 'trash']" fixed-width></font-awesome-icon>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
                <v-card class="mt-2">
                  <v-card-text>
                    <div>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <font-awesome-icon v-on="on" style="position: absolute; top: 5px; right: 5px;" :icon="['fas', 'circle-info']" fixed-width></font-awesome-icon>
                        </template>
                        <div class="text-center">
                          Add another date, if for example<br>
                          the trip can be extended.
                        </div>
                      </v-tooltip>
                      <v-btn block text outlined @click="doAddDate" :disabled="userRole === 'employee'">
                        Add more dates
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6">
                <v-date-picker
                  :disabled="this.activePicker.index === null || userRole === 'employee'"
                  header-color="primary"
                  color="primary"
                  full-width
                  :show-current="false"
                  v-model="dates"
                  :landscape="true"
                  :active-picker.sync="activePicker.mode"
                  :min="$moment().format('YYYY-MM-DD')"
                  range
                  :no-title="true"
                ></v-date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="doClose">Cancel</v-btn>
              </v-col>
              <v-col align="right">
                <div v-if="isNew">
                  <v-btn color="success" type="submit">Next</v-btn>
                </div>
                <div v-else>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-2">
                        Options
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <font-awesome-icon icon="fa-solid fa-money-bill"/>
                        <v-list-item-title @click="doOpenProducts" class="show-pointer ml-2">Costs</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <font-awesome-icon icon="fa-solid fa-location-pin"/>
                        <v-list-item-title @click="doOpenLocations" class="show-pointer ml-2">Locations</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn color="success" type="submit" class="ml-2" :disabled="userRole === 'employee'">Save</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: 'viewDates',
  data: () => ({
    isLoading: false,
    isOpen: false,
    isNew: false,
    activePicker: {
      refresh: 0,
      index: null,
      mode: null
    },
    trip: {
      id: Number(),
      name: String(),
      dates: []
    },
    userRole: null
  }),
  computed: {
    dates: {
      get: function () {
        console.log(`Date refresh ==> ${this.activePicker.refresh}`)

        if (this.activePicker.index !== null) {
          const date = this.trip.dates[this.activePicker.index]

          if (date === undefined) {
            return []
          } else {
            if (date.start !== '' && date.end !== '') {
              return [date.start, date.end]
            } else if (date.start !== '') {
              return [date.start]
            } else {
              return []
            }
          }
        } else {
          return []
        }
      },
      set: function (value) {
        this.activePicker.refresh++

        try {
          if (this.activePicker.index != null) {
            if (value.length === 1) {
              this.trip.dates[this.activePicker.index] = {
                id: this.trip.dates[this.activePicker.index].id,
                start: value[0],
                end: String()
              }
            } else if (value.length === 2) {
              this.trip.dates[this.activePicker.index] = {
                id: this.trip.dates[this.activePicker.index].id,
                start: value[0],
                end: value[1]
              }
            }
          }
        } catch (_) {
          if (this.trip.dates.length === 0) {
            this.doAddDate()

            this.trip.dates[this.activePicker.index] = {
              id: this.trip.dates[this.activePicker.index].id,
              start: value[0],
              end: String()
            }
          } else {
            this.$root.$emit('showWarning', 'You must select a date range or add one.')
          }
        }
      }
    }
  },
  methods: {
    doOpen (id, newTrip = false) {
      this.doInit()
      this.isLoading = true
      this.trip.id = id
      this.isNew = newTrip
      this.userRole = this.$store.getters.doGetRole

      new Promise((resolve, reject) => {
        this.$services.trip.doGetById(this.trip.id).then(trip => {
          resolve(trip)
        }).catch(error => {
          reject(error)
        })
      }).then(trip => {
        this.trip = trip

        if (trip.dates.length === 0) {
          this.trip.dates.push({
            start: String(),
            end: String()
          })
        }

        this.activePicker.dates = this.trip.dates.map(date => {
          return [date.start, date.end]
        })

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doOpenProducts () {
      this.$emit('viewProducts', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doOpenLocations () {
      this.$emit('viewLocations', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doClose () {
      this.isOpen = false
      this.$emit('doLoad')
    },
    doSave () {
      this.isLoading = true
      const actions = []

      this.trip.dates.forEach(date => {
        actions.push(new Promise((resolve, reject) => {
          if (date.id !== undefined) {
            this.$services.trip.date.doUpdate(date.id, date.start, date.end).then(tripDate => {
              resolve(tripDate)
            }).catch(error => {
              reject(error)
            })
          } else {
            this.$services.trip.date.doCreate(this.trip.id, date.start, date.end).then(tripDate => {
              resolve(tripDate)
            }).catch(error => {
              reject(error)
            })
          }
        }))
      })

      Promise.all(actions).then(_ => {
        this.doClose()

        if (this.isNew) {
          this.doOpenProducts()
        }
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doAddDate () {
      this.trip.dates.push({
        start: String(),
        end: String()
      })
    },
    doRemoveDate (index) {
      new Promise((resolve, reject) => {
        new Promise((resolve, reject) => {
          if ('products' in this.trip.dates[index]) {
            if (this.trip.dates[index].products.length >= 1) {
              reject(new Error('You cannot delete a date range with products assigned to it. Delete the products first or edit the dates.'))
            } else {
              resolve()
            }
          } else {
            resolve()
          }
        }).then(_ => {
          if ('id' in this.trip.dates[index]) {
            if (this.trip.dates[index].id === undefined) {
              resolve()
            } else {
              this.isLoading = true

              this.$services.trip.date.doDestroy(this.trip.dates[index].id).then(_ => {
                resolve()
              }).catch(error => {
                reject(error)
              })
            }
          } else {
            resolve()
          }
        }).catch(error => {
          reject(error)
        })
      }).then(_ => {
        this.trip.dates.splice(index, 1)
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doCalcNights (start, end) {
      return (start !== '' && end !== '' ? Number(this.$moment(end).diff(this.$moment(start), 'days')) : 0)
    },
    doInit () {
      this.activePicker.mode = null
      this.activePicker.dates = []
      this.activePicker.index = 0
      this.trip = {
        id: Number(),
        name: String(),
        dates: []
      }
    }
  }
}
</script>

<style scoped>

</style>
