<template>
  <span>
    <v-overlay :value="isLoading" opacity="0.7" z-index="500">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
    <v-card>
      <v-card-title class="text-h5">
        <span>
          You sure you want to <span class="text-decoration-underline red--text"> Delete</span>
        </span>
        <span>
          <span>
            {{ tripProduct.name }} ?
          </span>
        </span>
      </v-card-title>
      <v-card-text>Deleting this product should be self explanatory, however let's describe it. This will remove ALL information correlated to this trip product from Sea2Sea Scuba. Please be sure you want to do this!</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="doClose">
          Cancel
        </v-btn>
        <v-btn color="red" text @click="doDestroy">
          Destroy
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'deleteProduct',
  data: () => ({
    isLoading: false,
    isOpen: false,
    tripProduct: {
      id: 0,
      name: String()
    }
  }),
  methods: {
    doOpen (id) {
      this.isLoading = true

      this.$services.trip.product.doGetById(id).then(tripProduct => {
        this.tripProduct = tripProduct

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doDestroy () {
      this.isOpen = false

      this.isLoading = true
      this.$services.trip.product.doDestroy(this.tripProduct.id).then().catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.$emit('didDestroy')
        this.isLoading = false
      })
    },
    doClose () {
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
