<template>
  <section>
    <v-overlay :value="isLoading" opacity="0.7" z-index="500">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="1200px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <span class="text-h5">{{ trip.name }} Locations</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="doSave">
            <v-row>
              <v-col>
                <div class="text-center">
                  Locations is a feature that is not ready for production. <br>
                  This will simply be a way for Customers to see locations<br>
                  during a trip.<br><br>
                  You can ignore this message.
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="doClose">Cancel</v-btn>
              </v-col>
              <v-col align="right">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-2">
                      Options
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <font-awesome-icon icon="fa-solid fa-calendar-days"/>
                      <v-list-item-title @click="doOpenDates" class="show-pointer ml-2">Dates</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <font-awesome-icon icon="fa-solid fa-money-bill"/>
                      <v-list-item-title @click="doOpenProducts" class="show-pointer ml-2">Costs</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn color="success" class="ml-2" type="submit">Save</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: 'viewLocations',
  data: () => ({
    isLoading: false,
    isOpen: false,
    trip: {
      id: Number(),
      name: String()
    }
  }),
  methods: {
    doOpen (id) {
      this.isLoading = true
      this.trip.id = id

      new Promise((resolve, reject) => {
        this.$services.trip.doGetById(this.trip.id).then(trip => {
          resolve(trip)
        }).catch(error => {
          reject(error)
        })
      }).then(trip => {
        this.trip = trip
        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doClose () {
      this.isOpen = false
    },
    doOpenDates () {
      this.$emit('viewDates', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doOpenProducts () {
      this.$emit('viewProducts', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doSave () {
      this.doClose()
    }
  }
}
</script>

<style scoped>

</style>
