var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.userRole === 'employee'},on:{"click":_vm.doCreateTrip}},[_vm._v(" Create Trip ")])],1)],1),_c('v-card',[_c('v-card-title',[_c('div',[_c('v-text-field',{staticStyle:{"width":"300px"},attrs:{"label":"Search","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.trips,"search":_vm.search,"item-key":"id","loading":_vm.isLoading,"loading-text":"Loading trips","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"click:row":function($event){return _vm.doOpenRow($event)},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4 font-weight-bold text-h6"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4 text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.subtitle)+" ")])]}},{key:"item.dates",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4"},[_c('v-chip',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.doOpenDates($event, item.id)}}},[_c('font-awesome-icon',{attrs:{"size":"sm","icon":['fas','calendar-days'],"fixed-width":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.doGrabBestDateRange(item.dates)))])],1)],1)]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4"},[_c('v-chip',{attrs:{"color":"success"},on:{"click":function($event){return _vm.doOpenProducts($event, item.id)}}},[_c('font-awesome-icon',{attrs:{"size":"sm","icon":['fas','dollar-sign'],"fixed-width":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.doGrabBestPriceRange(item.tripProductCount)))])],1)],1)]}},{key:"item.daysUntil",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4"},[(item.daysUntil !== null)?_c('div',[_c('v-chip',{attrs:{"color":"success"},on:{"click":function($event){return _vm.doOpenDates($event, item.id)}}},[_c('font-awesome-icon',{attrs:{"size":"sm","icon":['fas','clock'],"fixed-width":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.daysUntil)+" "),_c('span',[_vm._v(" "+_vm._s(item.dayUntil > 1 ? 'day' : 'days'))])])],1)],1):(item.daysUntilEnd !== null)?_c('div',[_c('font-awesome-icon',{staticClass:"primary--text mx-3",attrs:{"icon":['fas', 'plane'],"size":"xl"}})],1):_c('div',[_c('font-awesome-icon',{staticClass:"error--text mx-3",attrs:{"icon":['fas', 'plane-arrival'],"size":"xl"}})],1)])]}},{key:"item.isFilled",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"color":"success","hide-details":"","disabled":_vm.userRole === 'employee'},on:{"click":function($event){return _vm.doToggleFilled($event, item)}},model:{value:(item.isFilled),callback:function ($$v) {_vm.$set(item, "isFilled", $$v)},expression:"item.isFilled"}})]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-switch',{staticClass:"mt-0",attrs:{"color":"success","hide-details":"","disabled":_vm.userRole === 'employee'},on:{"click":function($event){return _vm.doToggleActivation($event, item.id)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}}),(item.daysUntil === null && item.isActive)?_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('font-awesome-icon',_vm._g({staticClass:"mt-1",attrs:{"icon":['fas', 'circle-exclamation'],"fixed-width":"","size":"lg"}},on))]}}],null,true)},[_c('div',{staticClass:"text-center"},[_vm._v(" Trip is in the past"),_c('br'),_vm._v(" so it is hidden from the public. ")])])],1):_vm._e()],1)]}}])})],1)],1),_c('create-trip',{ref:"createTrip",on:{"doLoad":_vm.doLoad,"viewDates":function (id, isNew) { return _vm.doOpenDates(null, id, isNew); },"viewProducts":function (id) { return _vm.doOpenProducts(null, id); },"viewLocations":function (id) { return _vm.doOpenLocations(null, id); }}}),_c('toggle-activation',{ref:"toggleActivation",on:{"doLoad":_vm.doLoad}}),_c('view-dates',{ref:"viewDates",on:{"doLoad":_vm.doLoad,"viewProducts":function (id) { return _vm.doOpenProducts(null, id); },"viewLocations":function (id) { return _vm.doOpenLocations(null, id); }}}),_c('view-products',{ref:"viewProducts",on:{"doLoad":_vm.doLoad,"viewDates":function (id) { return _vm.doOpenDates(null, id); },"viewLocations":function (id) { return _vm.doOpenLocations(null, id); }}}),_c('view-locations',{ref:"viewLocations",on:{"doLoad":_vm.doLoad,"viewDates":function (id) { return _vm.doOpenDates(null, id); },"viewProducts":function (id) { return _vm.doOpenProducts(null, id); }}}),_c('view-trip',{ref:"viewTrip",on:{"doLoad":_vm.doLoad,"viewDates":function (id) { return _vm.doOpenDates(null, id); },"viewProducts":function (id) { return _vm.doOpenProducts(null, id); },"viewLocations":function (id) { return _vm.doOpenLocations(null, id); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }