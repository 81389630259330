<template>
  <section>
    <v-overlay :value="isLoading" opacity="0.7" z-index="500">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="500px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-form @submit.prevent="doSave" class="mt-6 px-4" ref="form">
          <v-card-title class="pt-0 mb-2">
            <span class="text-h5">Add a Product</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-autocomplete outlined label="Category" v-model="product.tripProductCategoryId" :items="productCategories" item-text="name" item-value="id" :rules="inputs.rules.simplyRequired">
                <template v-slot:selection="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-row>
            <v-row>
              <v-expand-transition>
                <span v-show="productNameHints.length >= 1" class="mb-1">Tips for a name: <span class="font-weight-bold">{{ productNameHintText }}</span></span>
              </v-expand-transition>
            </v-row>
            <v-row>
                <v-text-field outlined label="Name" v-model="product.name" hint="The generalized name of the Product for the Trip" :rules="inputs.rules.simplyRequired"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field prepend-inner-icon="fa-dollar-sign" label="Cost" v-model.number="costAmount" outlined hint="Purchase price for this Product" :rules="inputs.rules.simplyRequiredNumbers"></v-text-field>
            </v-row>
            <v-row>
              <v-expand-transition>
                <div v-show="requiredProducts.length >= 1" style="width: 100%;">
                  <div class="subtitle-2">
                    Required purchase grand total with this Product
                    <div class="font-weight-black title">${{ grandTotalWithRequired }}</div>
                  </div>
                  <v-expansion-panels class="my-4">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-10">
                        <span class="title">{{ requiredProducts.length }} Required Product(s)</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-list>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content>
                              <v-row no-gutters>
                                <v-col cols="4" justify="center" v-for="(requiredProduct, index) in requiredProducts" :key="index">
                                  <v-card max-width="100px" max-height="100px" class="ma-2">
                                    <v-card-text class="pa-0">
                                      <v-list>
                                        <v-list-item class="rounded-lg">
                                          <v-list-item-content class="rounded-lg">
                                            <v-list-item-title>
                                              {{ requiredProduct.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                              {{ requiredProduct.category.name }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                              ${{ requiredProduct.cents / 100 }}
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-expand-transition>
            </v-row>
            <v-row>
                <v-textarea outlined rows="2" label="Description" hint="Short description of what this product is" v-model="product.description" :rules="inputs.rules.simplyRequired"></v-textarea>
            </v-row>
            <v-row class="my-0">
              <v-checkbox outlined label="Required Purchase" off-icon="fa-regular fa-square" v-model="product.isRequired" persistent-hint hint="Does this need to be purchased for the Trip?"></v-checkbox>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="doClose">Cancel</v-btn>
              </v-col>
              <v-col align="right">
                <v-btn color="success" type="submit" class="ml-2">Save</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: 'createProduct',
  data: () => ({
    isLoading: false,
    isOpen: false,
    productCategories: [],
    productNameHints: [],
    productNameHintText: String(),
    currentTyper: null,
    isValidated: null,
    tripId: null,
    products: [],
    product: {
      tripDateId: Number(),
      tripProductCategoryId: null,
      name: null,
      cents: null,
      description: null,
      isRequired: true
    }
  }),
  computed: {
    costAmount: {
      get: function () {
        return (this.product.cents / 100)
      },
      set: function (newValue) {
        this.product.cents = newValue * 100
      }
    },
    requiredProducts () {
      return this.products.filter(product => {
        if (product.isRequired) { return product }
      })
    },
    grandTotalWithRequired () {
      let totalCents = this.product.cents

      this.requiredProducts.forEach(product => {
        totalCents = totalCents + product.cents
      })

      return (totalCents / 100).toFixed(2)
    }
  },
  methods: {
    doOpen (tripId, tripDateId) {
      this.tripId = tripId
      this.product.tripDateId = tripDateId
      this.isOpen = true

      this.doLoadCategories()
      this.doLoadProducts()
    },
    doSave () {
      this.isValidated = this.$refs.form.validate()

      if (this.isValidated) {
        this.isLoading = true

        this.$services.trip.product.doCreate(this.product.tripDateId, this.product.tripProductCategoryId, this.product.name, this.product.description, this.product.cents, this.product.isRequired).then(_ => {
          this.doClose()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
        })
      }
    },
    doInit () {
      this.productCategories = []
      this.productNameHints = []
      this.productNameHintText = String()
      this.currentTyper = null
      this.isValidated = null
      this.tripId = null
      this.products = []
      this.product = {
        tripDateId: Number(),
        tripProductCategoryId: null,
        name: null,
        cents: null,
        description: null,
        isRequired: true
      }
    },
    doLoadCategories () {
      this.isLoading = true

      this.$services.trip.product.category.doGetAll().then(categories => {
        this.productCategories = categories
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doLoadProducts () {
      this.isLoading = true

      this.$services.trip.product.doGetByDateId(this.product.tripDateId).then(products => {
        this.products = products
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doClose () {
      this.$refs.form.resetValidation()
      this.$emit('doLoad', this.tripId)
      clearTimeout(this.currentTyper)
      this.doInit()
      this.isOpen = false
    },
    typeHints (index = 0, characterAt = 0) {
      clearTimeout(this.currentTyper)

      const currentText = this.productNameHints[index]

      if (characterAt < currentText.length) {
        this.productNameHintText += currentText.charAt(characterAt)

        this.currentTyper = setTimeout(() => {
          this.typeHints(index, characterAt + 1)
        }, 150)
      } else {
        this.currentTyper = setTimeout(() => {
          this.productNameHintText = String()
        }, 4000)

        this.currentTyper = setTimeout(() => {
          if ((this.productNameHints.length - 1) <= index) {
            this.typeHints(0)
          } else {
            this.typeHints(index + 1)
          }
        }, 5000)
      }
    }
  },
  watch: {
    'product.tripProductCategoryId': {
      handler (newValue, _) {
        this.productNameHints = this.productCategories.filter(productCategory => {
          if (productCategory.id === newValue) {
            return productCategory
          }
        })[0].productHints
      }
    },
    productNameHints: {
      handler (newValue, _) {
        clearTimeout(this.currentTyper)
        this.productNameHintText = String()
        this.typeHints(0)
      }
    }
  }
}
</script>

<style scoped>

</style>
