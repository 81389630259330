<template>
  <span>
    <v-overlay :value="isLoading" opacity="0.7" z-index="500">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="1200px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <span class="text-h5">Trip Information</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="doCreate" fast-fail ref="form">
            <v-row>
              <v-col>
                <v-text-field outlined v-model="trip.name" label="Name" placeholder="Cozumel" :rules="inputs.rules.simplyRequired"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-textarea outlined v-model="trip.subtitle" label="Subtitle" placeholder="Cozumel is known as one of the best places to dive in the world! Join Sea2Sea Scuba on a diving adventure of a lifetime." hint="Short description of the Trip" no-resize rows="2" :rules="inputs.rules.simplyRequired" :counter="250"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox v-model="areImagesSetDuringValidation" off-icon="fa-regular fa-square" :rules="inputs.rules.simplyRequired" style="display: none;" :disabled="true"></v-checkbox>
                <image-upload-and-shuffle ref="images" :class="areImagesSetDuringValidation ? 'rounded' : 'customError rounded'" @change="doLoadImages"></image-upload-and-shuffle>
                <span class="caption red--text ml-2" v-if="!areImagesSetDuringValidation">At least one image is required</span>
              </v-col>
              <v-col>
                <v-btn @click="doOpenContentEditor()" :class="!(isValidated !== null && !didOpenEditor) ? 'rounded' : 'customError rounded'" depressed outlined tile large block plain>Content and Description</v-btn>
                <span class="caption ml-1 red--text" v-if="(isValidated !== null && !didOpenEditor)">Don't forget to view trip content and description before submitting</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="doClose">Cancel</v-btn>
              </v-col>
              <v-col align="right">
                <div>
                  <v-btn color="primary" class="mr-2" @click="doCreate(false)">Save and Continue later</v-btn>
                  <v-btn color="success" type="submit">Next</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <wysiwyg-editor ref="editor" @Save="doSaveContent"></wysiwyg-editor>
  </span>
</template>

<script>
import WysiwygEditor from '@/components/tools/wysiwygEditor.vue'
import ImageUploadAndShuffle from '@/components/image/ImageUploadAndShuffle.vue'

export default {
  name: 'createTrip',
  components: { ImageUploadAndShuffle, WysiwygEditor },
  data: () => ({
    isLoading: false,
    isOpen: false,
    productCategories: [],
    isValidated: null,
    didOpenEditor: false,
    trip: {
      name: String(),
      subtitle: String(),
      content: String(),
      images: []
    }
  }),
  computed: {
    areImagesSetDuringValidation () {
      if (this.isValidated !== null) {
        return (this.trip.images.length >= 1)
      } else {
        return true
      }
    }
  },
  methods: {
    doOpen () {
      this.isOpen = true

      this.doInit()
    },
    doLoadImages () {
      this.trip.images = this.$refs.images.images.filter(image => image.url != null)
    },
    doCreate (next = true) {
      this.doLoadImages()
      this.isValidated = this.$refs.form.validate()

      if (this.isValidated && (this.trip.images.length >= 1) && this.didOpenEditor) {
        this.isLoading = true

        this.$services.trip.doCreate(this.trip.name, this.trip.subtitle, this.trip.content, this.trip.images, false).then(trip => {
          this.trip = trip

          if (next) {
            this.doNext()
          }

          this.$nextTick(() => {
            this.doClose()
          })
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
        })
      }
    },
    doNext () {
      this.$emit('viewDates', this.trip.id, true)
    },
    doClose () {
      this.$refs.form.resetValidation()
      this.isOpen = false
      this.$emit('doLoad')
    },
    doOpenContentEditor () {
      this.didOpenEditor = true
      this.$refs.editor.doOpen('Trip content and description', this.trip.content)
    },
    doSaveContent () {
      this.trip.content = this.$refs.editor.content
    },
    doInit () {
      this.$nextTick(() => {
        this.$refs.images.doInit()
      })

      this.productCategories = []
      this.isValidated = null
      this.didOpenEditor = false
      this.trip = {
        name: String(),
        subtitle: String(),
        content: '<h1>This is the trip content and description.</h1><p>You can put anything you want in here.</p><p>Here are some tips for effective information, You can always edit this later and more information.</p><ol><li><p>The more information the better! The trips page will look exactly like what you put in here. </p></li><li><p>Include links and descriptions to hotels and destinations and even YouTube Videos!</p></li><li><p>Reference some of the dives and experiences the diver will be able to enjoy.</p></li><li><p>Market Sea2Sea and how the community is an Opening and Welcoming environment to welcome new divers!</p></li><li><p>Put in Information and use special styling to <strong>stress</strong> its importance!</p></li><li><p>Include the level of diver required and maybe if <u>additional</u> costs may be needed <u>while</u> on the trip.</p></li></ol><p></p><p>Here are some <u><strong>Do not do</strong></u> tips.</p><ol><li><p>Don\'t be redundant, sometimes if you put in the same thing over, and over search engines like google will flag the page.</p></li><li><p>Don\'t put in Trip Cost within the Content! Why? Let the site display these details natively. They will get a price breakdown based of the options put in the trip configurations.</p></li><li><p>Don\'t put in the Trip Dates, The same reason above. Let the site display this because it is configurable for the customer.</p></li></ol><p></p>',
        images: []
      }
    }
  }
}
</script>

<style scoped>
.customError {
  border: 2px red solid;
  color: red;
}
</style>
