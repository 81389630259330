<template>
  <span>
    <v-overlay :value="isLoading" opacity="0.7" z-index="500">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
    <v-card>
      <v-card-title class="text-h5">
        <span>
          You sure you want to <span class="text-decoration-underline"> {{ trip.isActive ? 'Disable' : 'Enable' }}</span>
        </span>
        <span>
          <span>
            {{ trip.name }} ?
          </span>
        </span>
      </v-card-title>
      <v-card-text>{{ trip.isActive ? 'Disabling this trip will prevent the site from displaying it\'s existence' : 'Enabling this trip will display the trip on the site and allow purchase.' }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="doClose">
          Cancel
        </v-btn>
        <v-btn :color="trip.isActive ? 'red' : 'success'" text @click="doToggleActivation">
          {{ trip.isActive ? 'Disable' : 'Enable' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'toggleActivation',
  data: () => ({
    isLoading: false,
    isOpen: false,
    trip: {
      id: 0,
      name: String(),
      isActive: null
    }
  }),
  methods: {
    doOpen (id) {
      this.trip.id = id
      this.isLoading = true

      this.$services.trip.doGetById(id).then(trip => {
        this.trip.name = trip.name
        this.trip.isActive = trip.isActive

        if (trip.dates.length === 0) {
          this.$root.$emit('showError', 'This trip does not have any dates associated!')
          this.$nextTick(_ => {
            this.doClose()
          })
        } else if (!trip.isActive && trip.tripProductCount === 0) {
          this.$root.$emit('showWarning', 'This trip does not have any costs associated, Enabling this trip will not be purchasable!')
        }

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doToggleActivation () {
      this.isOpen = false

      this.isLoading = true

      if (this.trip.isActive) {
        this.$services.trip.doDeactivate(this.trip.id).then().catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
          this.doClose()
        })
      } else {
        this.$services.trip.doActivate(this.trip.id).then().catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
          this.doClose()
        })
      }
    },
    doClose () {
      this.$emit('doLoad')
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
