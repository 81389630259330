<template>
  <div>
    <v-container>
      <v-row class="mb-4">
        <v-col align="right">
          <v-btn class="primary" @click="doCreateTrip" :disabled="userRole === 'employee'">
            Create Trip
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <div>
            <v-text-field v-model="search" label="Search" outlined style="width: 300px;"></v-text-field>
          </div>
        </v-card-title>
        <v-data-table :headers="headers" :items="trips" :search="search" class="elevation-1" item-key="id" :loading="isLoading" loading-text="Loading trips" @click:row="doOpenRow($event)" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
          <template v-slot:top></template>
          <template v-slot:item.name="{ item }">
            <div class="py-4 font-weight-bold text-h6">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.subtitle="{ item }">
            <div class="py-4 text-truncate" style="max-width: 200px">
              {{ item.subtitle }}
            </div>
          </template>
          <template v-slot:item.dates="{ item }">
            <div class="py-4">
              <v-chip color="primary" @click="doOpenDates($event, item.id)">
                <font-awesome-icon size="sm" :icon="['fas','calendar-days']" fixed-width></font-awesome-icon>
                <span class="ml-2">{{ doGrabBestDateRange(item.dates) }}</span>
              </v-chip>
            </div>
          </template>
          <template v-slot:item.products="{ item }">
            <div class="py-4">
              <v-chip color="success" @click="doOpenProducts($event, item.id)">
                <font-awesome-icon size="sm" :icon="['fas','dollar-sign']" fixed-width></font-awesome-icon>
                <span class="ml-2">{{ doGrabBestPriceRange(item.tripProductCount) }}</span>
              </v-chip>
            </div>
          </template>
          <template v-slot:item.daysUntil="{ item }">
            <div class="py-4">
              <div v-if="item.daysUntil !== null">
                <v-chip color="success" @click="doOpenDates($event, item.id)">
                  <font-awesome-icon size="sm" :icon="['fas','clock']" fixed-width></font-awesome-icon>
                  <span class="ml-2">{{ item.daysUntil }} <span> {{ item.dayUntil > 1 ? 'day' : 'days' }}</span></span>
                </v-chip>
              </div>
              <div v-else-if="item.daysUntilEnd !== null">
                <font-awesome-icon :icon="['fas', 'plane']" size="xl" class="primary--text mx-3"/>
              </div>
              <div v-else>
                <font-awesome-icon :icon="['fas', 'plane-arrival']" size="xl" class="error--text mx-3"/>
              </div>
            </div>
          </template>
          <template v-slot:item.isFilled="{ item }">
            <v-switch class="mt-0" color="success" @click="doToggleFilled($event, item)" v-model="item.isFilled" hide-details :disabled="userRole === 'employee'"></v-switch>
          </template>
          <template v-slot:item.isActive="{ item }">
            <div class="d-flex align-center justify-start">
              <v-switch class="mt-0" color="success" @click="doToggleActivation($event, item.id)" v-model="item.isActive" hide-details :disabled="userRole === 'employee'"></v-switch>
              <div v-if="item.daysUntil === null && item.isActive">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <font-awesome-icon v-on="on" class="mt-1" :icon="['fas', 'circle-exclamation']" fixed-width size="lg"></font-awesome-icon>
                  </template>
                  <div class="text-center">
                    Trip is in the past<br>
                    so it is hidden from the public.
                  </div>
                </v-tooltip>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <create-trip ref="createTrip" @doLoad="doLoad" @viewDates="(id, isNew) => doOpenDates(null, id, isNew)" @viewProducts="(id) => doOpenProducts(null, id)" @viewLocations="(id) => doOpenLocations(null, id)"></create-trip>
    <toggle-activation ref="toggleActivation" @doLoad="doLoad"></toggle-activation>
    <view-dates ref="viewDates" @doLoad="doLoad" @viewProducts="(id) => doOpenProducts(null, id)" @viewLocations="(id) => doOpenLocations(null, id)"></view-dates>
    <view-products ref="viewProducts" @doLoad="doLoad" @viewDates="(id) => doOpenDates(null, id)" @viewLocations="(id) => doOpenLocations(null, id)"></view-products>
    <view-locations ref="viewLocations" @doLoad="doLoad" @viewDates="(id) => doOpenDates(null, id)" @viewProducts="(id) => doOpenProducts(null, id)"></view-locations>
    <view-trip ref="viewTrip" @doLoad="doLoad" @viewDates="(id) => doOpenDates(null, id)" @viewProducts="(id) => doOpenProducts(null, id)" @viewLocations="(id) => doOpenLocations(null, id)"></view-trip>
  </div>
</template>

<script>
import CreateTrip from '@/views/admin/trips/createTrip.vue'
import ToggleActivation from '@/views/admin/trips/toggleActivation.vue'
import ViewDates from '@/views/admin/trips/viewDates.vue'
import ViewProducts from '@/views/admin/trips/viewProducts.vue'
import ViewTrip from '@/views/admin/trips/viewTrip.vue'
import ViewLocations from '@/views/admin/trips/viewLocations.vue'

export default {
  name: 'Trips',
  components: { ViewLocations, ViewTrip, ViewProducts, ViewDates, ToggleActivation, CreateTrip },
  data: () => ({
    isLoading: false,
    search: String(),
    sortBy: 'daysUntil',
    sortDesc: true,
    headers: [
      {
        text: 'Name',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Subtitle',
        sortable: false,
        value: 'subtitle'
      },
      {
        text: 'Dates',
        sortable: false,
        value: 'dates'
      },
      {
        text: 'Costs',
        sortable: false,
        value: 'products'
      },
      {
        align: 'start',
        text: 'Days till',
        sortable: true,
        value: 'daysUntil'
      },
      {
        text: 'Filled',
        sortable: true,
        value: 'isFilled'
      },
      {
        text: 'Active',
        value: 'isActive'
      }
    ],
    trips: [],
    userRole: null
  }),
  methods: {
    doCreateTrip () {
      this.$refs.createTrip.doOpen()
    },
    doLoad () {
      this.isLoading = true
      this.userRole = this.$store.getters.doGetRole

      this.$services.trip.doGetAll().then(trips => {
        this.trips = trips
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doOpenRow (row) {
      this.$refs.viewTrip.doOpen(row.id)
    },
    doOpenDates (event, id, isNew) {
      if (event !== null) {
        event.stopPropagation()
      }

      this.$refs.viewDates.doOpen(id, isNew)
    },
    doOpenProducts (event, id) {
      if (event !== null) {
        event.stopPropagation()
      }

      this.$refs.viewProducts.doOpen(id)
    },
    doOpenLocations (event, id) {
      if (event !== null) {
        event.stopPropagation()
      }

      this.$refs.viewLocations.doOpen(id)
    },
    doToggleFilled (event, trip) {
      if (event !== null) {
        event.stopPropagation()
      }

      new Promise((resolve, reject) => {
        if (trip.isFilled) { // The event click value (because of the v-model)
          this.$services.trip.doFill(trip.id).then(_ => resolve()).catch(error => reject(error))
        } else {
          this.$services.trip.doUnfill(trip.id).then(_ => resolve()).catch(error => reject(error))
        }
      }).then(_ => {

      }).catch(error => {
        this.doLoad()
        this.$root.$emit('showError', error.message)
      })
    },
    doToggleActivation (event, id) {
      if (event !== null) {
        event.stopPropagation()
      }

      this.$refs.toggleActivation.doOpen(id)
    },
    doGrabBestDateRange (dates) {
      if (dates.length === 1) {
        return `${this.doCalcNights(dates[0].start, dates[0].end)} Night trip`
      } else if (dates.length >= 2) {
        return `${dates.length} Date ranges`
      } else {
        return 'No dates scheduled'
      }
    },
    doGrabBestPriceRange (count) {
      if (count >= 1) {
        return `${count} Products available`
      } else {
        return 'No products available'
      }
    },
    doCalcNights (start, end) {
      return (start !== '' && end !== '' ? Number(this.$moment(end).diff(this.$moment(start), 'days')) : 0)
    }
  },
  mounted () {
    this.doLoad()
  }
}
</script>

<style scoped>

</style>
