<template>
  <span>
    <v-overlay :value="isLoading" opacity="0.7" z-index="500">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="1200px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <span class="text-h5">Trip Information</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="doUpdate" fast-fail ref="form">
            <v-row>
              <v-col>
                <v-text-field outlined v-model="trip.name" label="Name" placeholder="Cozumel" :rules="inputs.rules.simplyRequired"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-textarea outlined v-model="trip.subtitle" label="Subtitle" placeholder="Cozumel is known as one of the best places to dive in the world! Join Sea2Sea Scuba on a diving adventure of a lifetime." hint="Short description of the Trip" no-resize rows="2" :rules="inputs.rules.simplyRequired" :counter="250"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox v-model="areImagesSetDuringValidation" off-icon="fa-regular fa-square" :rules="inputs.rules.simplyRequired" style="display: none;" :disabled="true"></v-checkbox>
                <image-upload-and-shuffle ref="images" :class="areImagesSetDuringValidation ? 'rounded' : 'customError rounded'" @change="doLoadImages"></image-upload-and-shuffle>
                <span class="caption red--text ml-2" v-if="!areImagesSetDuringValidation">At least one image is required</span>
              </v-col>
              <v-col>
                <v-btn @click="doOpenContentEditor()" class="rounded" depressed outlined tile large block plain>Content and Description</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <v-btn @click="doClose">Cancel</v-btn>
                </div>
              </v-col>
              <v-col align="right">
                <div>
                  <v-btn @click="doDestroy" color="error" :disabled="userRole === 'employee'">Delete</v-btn>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-2">
                          Options
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <font-awesome-icon icon="fa-solid fa-calendar-days"/>
                          <v-list-item-title @click="doOpenDates" class="show-pointer ml-2">Dates</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <font-awesome-icon icon="fa-solid fa-money-bill"/>
                          <v-list-item-title @click="doOpenProducts" class="show-pointer ml-2">Costs</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <font-awesome-icon icon="fa-solid fa-location-pin"/>
                          <v-list-item-title @click="doOpenLocations" class="show-pointer ml-2">Locations</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  <v-btn color="success" class="ml-2" type="submit" :disabled="userRole === 'employee'">Save</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <wysiwyg-editor ref="editor" @Save="doSaveContent"></wysiwyg-editor>
    <delete-trip ref="deleteTrip" @didDestroy="doClose"></delete-trip>
  </span>
</template>

<script>
import WysiwygEditor from '@/components/tools/wysiwygEditor.vue'
import ImageUploadAndShuffle from '@/components/image/ImageUploadAndShuffle.vue'
import DeleteTrip from '@/views/admin/trips/deleteTrip.vue'

export default {
  name: 'viewTrip',
  components: { DeleteTrip, ImageUploadAndShuffle, WysiwygEditor },
  data: () => ({
    isLoading: false,
    isOpen: false,
    productCategories: [],
    isValidated: null,
    trip: {
      name: String(),
      subtitle: String(),
      content: String(),
      images: []
    },
    userRole: null
  }),
  computed: {
    areImagesSetDuringValidation () {
      if (this.isValidated !== null) {
        return (this.trip.images.length >= 1)
      } else {
        return true
      }
    }
  },
  methods: {
    doOpen (id) {
      this.isLoading = true
      this.trip.id = id
      this.userRole = this.$store.getters.doGetRole

      new Promise((resolve, reject) => {
        this.$services.trip.doGetById(this.trip.id).then(trip => {
          resolve(trip)
        }).catch(error => {
          reject(error)
        })
      }).then(trip => {
        this.trip = trip
        this.isOpen = true

        this.$nextTick(() => {
          this.$refs.images.doDefineImages(this.trip.images)
        })
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doUpdate () {
      this.doLoadImages()
      this.isValidated = this.$refs.form.validate()

      if (this.isValidated && (this.trip.images.length >= 1)) {
        this.isLoading = true

        this.$services.trip.doUpdate(this.trip.id, this.trip.name, this.trip.subtitle, this.trip.content, this.trip.images).then(_ => {
          this.doClose()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
        })
      }
    },
    doOpenProducts () {
      this.$emit('viewProducts', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doOpenDates () {
      this.$emit('viewDates', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doOpenLocations () {
      this.$emit('viewLocations', this.trip.id)

      this.$nextTick(() => {
        this.doClose()
      })
    },
    doLoadImages () {
      this.trip.images = this.$refs.images.images.filter(image => image.url != null)
    },
    doDestroy () {
      this.$refs.deleteTrip.doOpen(this.trip.id)
    },
    doClose () {
      this.$refs.form.resetValidation()
      this.isOpen = false
      this.$emit('doLoad')
    },
    doOpenContentEditor () {
      this.$refs.editor.doOpen('Trip content and description', this.trip.content)
    },
    doSaveContent () {
      this.trip.content = this.$refs.editor.content
    },
    doInit () {
      this.productCategories = []
      this.isValidated = null
      this.trip = {
        name: String(),
        subtitle: String(),
        content: '<h1>This is the trip content and description.</h1><p>You can put anything you want in here.</p><p>Here are some tips for effective information, You can always edit this later and more information.</p><ol><li><p>The more information the better! The trips page will look exactly like what you put in here. </p></li><li><p>Include links and descriptions to hotels and destinations and even YouTube Videos!</p></li><li><p>Reference some of the dives and experiences the diver will be able to enjoy.</p></li><li><p>Market Sea2Sea and how the community is an Opening and Welcoming environment to welcome new divers!</p></li><li><p>Put in Information and use special styling to <strong>stress</strong> its importance!</p></li><li><p>Include the level of diver required and maybe if <u>additional</u> costs may be needed <u>while</u> on the trip.</p></li></ol><p></p><p>Here are some <u><strong>Do not do</strong></u> tips.</p><ol><li><p>Don\'t be redundant, sometimes if you put in the same thing over, and over search engines like google will flag the page.</p></li><li><p>Don\'t put in Trip Cost within the Content! Why? Let the site display these details natively. They will get a price breakdown based of the options put in the trip configurations.</p></li><li><p>Don\'t put in the Trip Dates, The same reason above. Let the site display this because it is configurable for the customer.</p></li></ol><p></p>',
        images: []
      }
    }
  }
}
</script>

<style scoped>
.customError {
  border: 2px red solid;
  color: red;
}
</style>
